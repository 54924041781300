var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full md:w-1/2 lg:w-3/4" }, [
        _c("h2", { staticClass: "app-heading mt-2" }, [
          _vm._v("Widgets - " + _vm._s(_vm.activeAppName) + " "),
          _c(
            "a",
            {
              attrs: {
                href: "https://support.travelgenix.io/hc/en-gb/categories/360003650057--Widgets",
                target: "_blank",
              },
            },
            [
              _c("feather-icon", {
                attrs: {
                  title: "Support",
                  icon: "LifeBuoy",
                  svgClasses: "h-5 w-5 mr-4 text-primary",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "vx-row mt-2 match-height" },
      _vm._l(_vm.widgetList, function (widget, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-4" },
          [
            _c("application-widget-card", {
              attrs: {
                applicationId: _vm.applicationId,
                widget: widget,
                showCounter: true,
                showDescription: false,
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }