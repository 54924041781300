var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticStyle: { cursor: "pointer" },
      on: {
        click: function ($event) {
          return _vm.openWidget(_vm.widget.widgetId, _vm.widget.url)
        },
      },
    },
    [
      _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
        _c("img", {
          staticClass: "responsive card-img-top",
          attrs: { src: _vm.widget.thumbnail },
          on: { error: _vm.setAltImgForThumbnail },
        }),
      ]),
      _c("div", { staticClass: "flex flex-wrap justify-start" }, [
        _c("img", {
          staticStyle: { height: "32px" },
          attrs: { src: _vm.widget.icon },
          on: { error: _vm.setAltImgForIcon },
        }),
        _c("h5", { staticClass: "ml-2 mt-2" }, [
          _vm._v(_vm._s(_vm.widget.name)),
        ]),
      ]),
      _vm.showDescription
        ? _c("p", { staticClass: "text-grey mt-4" }, [
            _vm._v(_vm._s(_vm.strippedDescription)),
          ])
        : _vm._e(),
      _vm.showCounter
        ? _c("p", { staticClass: "mt-4" }, [
            _vm._v("Widgets: " + _vm._s(_vm.widget.count)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }